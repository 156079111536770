var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.$apollo.loading)?_c('Loading'):_c('div',[_c('v-card-text',{attrs:{"id":"links-edit__card-text"}},[_c('Fields',{attrs:{"id":"link-edit-fields","schema":{
        title: {
          type: 'string',
          label: 'Nombre',
          description: 'Debe ser único y reconocible.',
          newDesign: true
        },
        type: {
          label: 'Tipo de link',
          type: 'select',
          sizeSmall: 12,
          sizeLarge: 4,
          fieldOptions: {
            options: [
              { label: 'Ruta', value: 'path' },
              { label: 'Categoria', value: 'category' },
              { label: 'Menú de usuario', value: 'userMenu' }
            ]
          },
          newDesign: true
        },
        position: {
          type: 'number',
          label: 'Posición',
          sizeSmall: 12,
          sizeLarge: 4,
          newDesign: true
        },
        icon: {
          type: 'string',
          label: 'Ícono',
          description: 'Visitar https://material.io/icons para una lista de íconos.',
          sizeSmall: 12,
          sizeLarge: 4,
          fieldOptions: {
            icon: _vm.normalizedIcon
          },
          optional: true,
          newDesign: true
        }
      },"disabled":_vm.$apollo.loading || _vm.saving,"environmentVariables":{ environmentId: _vm.environmentId },"validationErrors":{}},model:{value:(_vm.link),callback:function ($$v) {_vm.link=$$v},expression:"link"}}),_c('draggable',{attrs:{"handle":".link-handle","animation":200,"scroll-sensitivity":128,"force-fallback":true},model:{value:(_vm.linkFields),callback:function ($$v) {_vm.linkFields=$$v},expression:"linkFields"}},[_c('v-slide-y-transition',{attrs:{"group":""}},_vm._l((_vm.linkFields),function(field,index){return _c('LinkFieldEdit',{key:index,attrs:{"link":field,"type":_vm.link.type,"disabled":_vm.$apollo.loading || _vm.saving,"environmentVariables":{ environmentId: _vm.environmentId },"validationErrors":{}},on:{"input":function($event){return _vm.handleFieldChange(index, $event)},"delete":function($event){return _vm.handleFieldDelete(index)}}})}),1)],1),(_vm.link.type === 'category')?_c('v-btn',{attrs:{"outlined":"","block":"","color":"primary"},on:{"click":_vm.addField}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("add")]),_vm._v(" Añadir Link ")],1):_vm._e()],1),_c('v-card-actions',[_c('v-btn',{attrs:{"text":"","icon":"","color":"error","disabled":_vm.$apollo.loading || _vm.saving,"title":"Eliminar Link"},on:{"click":_vm.deleteItem}},[_c('v-icon',[_vm._v("delete")])],1),_c('v-btn',{attrs:{"text":"","icon":"","title":"Revertir Cambios","disabled":!_vm.dirty || _vm.saving},on:{"click":_vm.update}},[_c('v-icon',[_vm._v("undo")])],1),_c('v-flex'),_c('v-btn',{attrs:{"color":"#59a4f4","dark":"","disabled":_vm.$apollo.loading || _vm.saving},on:{"click":_vm.dismiss}},[_vm._v("Cancelar")]),_c('v-btn',{attrs:{"color":"#59a4f4","dark":!_vm.dirty ? false : true,"disabled":!_vm.dirty,"loading":_vm.$apollo.loading || _vm.saving},on:{"click":_vm.save}},[_vm._v("Guardar")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }