







































































import { Vue, Component, Prop } from 'vue-property-decorator'
import { Link, LinkType } from '@/models'
import _range from 'lodash/range'
import _snakeCase from 'lodash/snakeCase'
import Fields from '@/components/form/Fields.vue'

@Component({
  components: {
    Fields
  }
})
export default class LinkFieldEdit extends Vue {
  @Prop({ type: Object, required: true }) link !: Link
  @Prop({ type: String, default: 'path' }) type !: String
  @Prop({ type: Boolean }) disabled !: boolean
  @Prop({ type: Object, default: () => ({}) }) environmentVariables !: Record<string, any>
  @Prop({ type: Object, default: () => ({}) }) validationErrors !: Record<string, string[]>

  get syncLink () {
    return this.link
  }

  set syncLink (v) {
    this.$emit('input', v)
  }

  get normalizedIcon () {
    if (!this.link) return ''
    const icon = this.link.icon || ''
    return icon.startsWith('Md') ? _snakeCase(icon.slice(2)) : icon
  }
}
