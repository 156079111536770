



























































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import gql from 'graphql-tag'
import Loading from '@/components/Loading.vue'
import draggable from 'vuedraggable'
import { Link, LinkType, LinkField } from '@/models'
import { LinkCreateFragment, LinkUpdateFragment } from './fragments'
import _cloneDeep from 'lodash/cloneDeep'
import _isEqual from 'lodash/isEqual'
import _range from 'lodash/range'
import _snakeCase from 'lodash/snakeCase'
import Fields from '@/components/form/Fields.vue'
import LinkFieldEdit from './FieldEdit.vue'
import cleanData from '@/utils/gql/cleanData'
import { confirmDelete } from '@/components/dialogs'

@Component({
  components: {
    Loading,
    Fields,
    LinkFieldEdit,
    draggable
  },
  apollo: {
    savedLink: {
      query: gql`query getLink($linkId: ID) {
        savedLink: link(linkId: $linkId) {
          ...LinkCreate
          ...LinkUpdate
        }
      }
      ${LinkCreateFragment}
      ${LinkUpdateFragment}`,
      variables () {
        return {
          linkId: this.componentId
        }
      },
      fetchPolicy: 'network-only'
    }
  }
})
export default class LinkEdit extends Vue {
  @Prop({ type: String, required: true }) environmentId !: String
  @Prop({ type: String, required: true }) componentId !: String

  savedLink : Link | null = null
  link : Partial<Link> = {}

  saving = false
  valid = false

  @Watch('savedLink')
  update (newData : Link) {
    this.$set(this, 'link', _cloneDeep(this.savedLink))
  }

  get normalizedIcon () {
    if (!this.link) return ''
    const icon = this.link.icon || ''
    return icon.startsWith('Md') ? _snakeCase(icon.slice(2)) : icon
  }

  get linkFields () {
    if (!this.link) return []

    switch (this.link.type) {
      case 'path':
      case 'userMenu':
        return [this.link]
      case 'category':
        return this.link.fields
    }
  }

  addField () {
    if (!this.link) return
    if (!this.link.fields) this.link.fields = []

    this.link.fields = [
      ...this.link.fields,
      { title: '', path: '', roles: [] }
    ]
  }

  handleFieldChange (index: number, field: Link | LinkField) {
    if (!this.link) return

    switch (this.link.type) {
      case 'path':
      case 'userMenu':
        this.link = field
        break
      case 'category':
        if (!this.link.fields) this.link.fields = []
        this.link.fields = this.link.fields.map((f, i) => i === index ? { ...f, ...field } : f)
        break
    }
  }

  handleFieldDelete (index: number) {
    if (!this.link || !this.link.fields || this.link.type !== 'category') return
    this.link.fields.splice(index, 1)
  }

  async save () {
    if (!this.link || this.saving) return
    this.saving = true
    try {
      const result = await this.$apollo.mutate({
        mutation: gql`mutation ($linkId: ID, $link: UpdateLinkInput) {
          updateLink (linkId: $linkId, link: $link) {
            ...LinkCreate
            ...LinkUpdate
          }
        }
        ${LinkCreateFragment}
        ${LinkUpdateFragment}`,
        // Parameters
        variables: {
          linkId: this.link._id,
          link: cleanData(this.link, LinkUpdateFragment)
        }
      })

      this.savedLink = result.data.updateLink
      this.$emit('save', this.savedLink)
    } catch (e) {
      this.$emit('error', e)
      console.error(e)
    } finally {
      this.saving = false
    }
  }

  async deleteItem () {
    if (!await confirmDelete('¿Seguro que quieres eliminar por completo este link del ambiente?')) return
    if (!this.link || this.saving) return
    this.saving = true
    try {
      const result = await this.$apollo.mutate({
        mutation: gql`mutation ($linkId : ID) {
          removeLink (linkId: $linkId)
        }`,
        // Parameters
        variables: {
          linkId: this.link._id
        }
      })
      this.$emit('delete', result.data.removeLink)
    } catch (e) {
      this.$emit('error', e)
      console.error(e)
    }
  }

  get dirty () {
    return !_isEqual(this.link, this.savedLink)
  }

  dismiss () {
    this.$emit('dismiss')
  }
}
