import gql from 'graphql-tag'

export const LinkCreateFragment = gql`
fragment LinkCreate on Link {
  _id
  environmentId
}
`

export const LinkUpdateFragment = gql`
fragment LinkUpdate on Link {
  title
  type
  icon
  path
  fields {
    title
    path
    icon
    showInHome
    sizeSmall
    sizeMedium
    sizeLarge
    roles
    textColor
    backgroundColor
  }
  roles
  position
}
`

export const LinkFragment = gql`
fragment Link on Link {
  ...LinkCreate
  ...LinkUpdate
  createdAt
}
${LinkCreateFragment}
${LinkUpdateFragment}
`
